<template>
  <!-- <PaymentModal
    v-if="paymentModalVisibility"
    @close="closePaymentModal"
    @submit="submitOrder"
  /> -->

  <Modal v-if="ticket" :modalOpened="modalOpened" @close="closeModal">
    <div class="buy-ticket-modal pb-2">
      <div
        class="text-size-16 text-center py-6 text-borderColor lora-bold workshop-signup-title"
      >
        {{ content.signUpForWorkshop }}
      </div>
      <div class="px-6">
        <div class="pt-3 text-size-12">{{ content.workshop }}</div>
        <div class="pb-3 signup-workshop-content mt-1">
          {{ workshop.title }}
        </div>
      </div>
      <div class="px-6">
        <div class="pt-3 text-size-12">{{ content.startDate }}</div>
        <!-- <div class="pb-3 signup-workshop-content mt-1">Oct 12, 2021</div> -->
        <div class="pb-3 signup-workshop-content mt-1">
          {{ month(workshop.date) }}
          {{ day(workshop.date) }}{{ content.coma }}
          {{ year(workshop.date) }}
        </div>
      </div>
      <div class="px-6">
        <div class="pt-3 text-size-12">{{ content.ticketType }}</div>
        <div class="pb-3 signup-workshop-content mt-1">
          {{ format(ticket.min) }} - {{ format(ticket.max) }}
          {{ content.attendees }}
        </div>
      </div>
      <div class="px-6">
        <div class="pt-3 text-size-12">{{ content.price }}</div>
        <div class="pb-3 signup-workshop-content mt-1">
          {{ format(ticket.price) }} {{ content.iqd }}
        </div>
      </div>
      <div class="px-6 my-6">
        <h4 class="text-center pb-3 mb-5 signup-workshop-content">
          {{ content.chooseMethod }}
        </h4>
        <div class="flex flex-col items-center justify-center space-y-3">
          <button
            class="w-full text-white alert-btn bg-beingDelivered grid grid-cols-3 items-center"
            @click="buyTicket('cash')"
          >
            <span class="col-span-1 flex justify-start px-2">
              <i class="fas fa-money-bill-wave-alt"></i>
            </span>
            <span class="col-span-2 flex justify-start">
              {{ content.cash }}
            </span>
          </button>
          <button
            class="w-full text-white alert-btn bg-nassWallet grid grid-cols-3 items-center"
            @click="buyTicket('nasspay')"
          >
            <span class="col-span-1 flex justify-start px-2">
              <img class="h-5" src="/static/images/nw-icon.png" />
            </span>
            <span class="col-span-2 flex justify-start">
              {{ content.nasswallet }}
            </span>
          </button>
          <button
            class="w-full text-white alert-btn bg-black grid grid-cols-3 items-center"
            @click="buyTicket('zaincash')"
          >
            <span class="col-span-1 flex justify-start px-2">
              <img class="h-5" src="/static/images/zaincash-icon.png" />
            </span>
            <span class="col-span-2 flex justify-start">
              {{ content.zaincash }}
            </span>
          </button>
          <button
            class="w-full text-white alert-btn bg-fibPayment grid grid-cols-3 items-center"
            @click="buyTicket('fib')"
          >
            <span class="col-span-1 flex justify-start px-2">
              <img class="h-5" src="/static/images/fib-icon.png" />
            </span>
            <span class="col-span-2 flex justify-start">
              {{ content.fib }}
            </span>
          </button>
          <!-- <button
          class="w-full text-white alert-btn bg-bluePrimary"
          @click="buyTicket('zaincash')"
        >
          {{ content.zaincash }}
        </button> -->
          <!-- <button
          class="w-full text-white alert-btn bg-bluePrimary"
          @click="buyTicket('fastpay')"
        >
          {{ content.fastpay }}
        </button> -->
        </div>
        <!-- <button
          class="
            header-btn
            hover:text-bluePrimary
            text-white
            bg-bluePrimary
            lora-bold
            whitespace-nowrap
            hover:bg-blueSecondary
            duration-300
            w-full
            mt-8
          "
          @click="buyTicket"
        >
          {{ content.purchaseTicket }}
        </button> -->
      </div>
    </div>
  </Modal>
  <WorkshopRegisterModal
    v-if="ticket"
    :show="showUnauthorizedUserModal"
    @close="showUnauthorizedUserModal = false"
    :workshop="workshop"
    :ticket="ticket"
  />

  <div class="upcoming-workshop-card relative">
    <div class="text-size-20 workshop-title">
      {{ workshop.title }}
    </div>
    <div class="workshop-date flex items-center justify-center flex-col">
      <div class="day text-size-36 lora-bold">{{ day(workshop.date) }}</div>
      <div class="year">{{ month(workshop.date) }}</div>
      <div class="year">{{ year(workshop.date) }}</div>
    </div>
    <div class="ticket-options my-11 text-borderColor">
      {{ content.ticketOptions }}
    </div>
    <div
      class="group-cards mb-4 grid items-center grid-cols-1 sm:grid-cols-2 gap-x-1"
      v-for="(ticket, index) in workshop.tickets"
      :key="ticket.id"
    >
      <div class="title flex flex-wrap items-center me-2">
        <div class="me-4 my-1">
          {{ content.groupOf }} {{ format(ticket.min) }}-{{
            format(ticket.max)
          }}
          {{ content.attendees }}
        </div>
        <div class="sold-out my-1" v-if="ticket.soldout">
          {{ content.soldOut }}
        </div>
      </div>
      <div class="flex flex-row flex-wrap items-center justify-between">
        <div class="price flex items-center my-1 me-2 whitespace-nowrap">
          {{ format(ticket.price) }} {{ content.iqd }}
        </div>
        <span
          v-if="ticket.soldout"
          class="register-workshop my-1 me-2 flex whitespace-nowrap items-center text-borderColor lora-bold text-opacity-70"
        >
          {{ content.signUp }}
        </span>
        <button
          v-else
          class="register-workshop my-1 me-2 flex whitespace-nowrap items-center text-borderColor lora-bold cursor-pointer"
          @click="openModal(index)"
        >
          {{ content.signUp }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "/src/assets/numberFormat";
import WorkshopRegisterModal from "/src/components/ui/Workshops/WorkshopRegisterModal.vue";
export default {
  inject: ["content"],
  props: ["workshop"],
  components: { WorkshopRegisterModal },
  data() {
    return {
      modalOpened: false,
      paymentModalVisibility: false,
      showUnauthorizedUserModal: false,
      ticket: null,
    };
  },
  computed: {
    auth() {
      return this.$store.getters["auth/auth"];
    },
    pending() {
      return this.$store.getters["auth/pending"];
    },
  },
  methods: {
    openModal(index) {
      if (!this.auth) {
        // this.$store.dispatch('feedback/setFeedback', {
        // 	type: 'fail',
        // 	title: this.content.fail,
        // 	message: this.content.needToSignIn,
        // });
        this.ticket = this.workshop.tickets[index];
        this.showUnauthorizedUserModal = true;
        return;
      }
      if (this.pending) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.needToBeVerified,
        });
        return;
      }
      this.ticket = this.workshop.tickets[index];
      this.modalOpened = true;
    },
    closeModal() {
      this.modalOpened = false;
      this.ticket = null;
    },
    closePaymentModal() {
      this.paymentModalVisibility = false;
    },
    year(date) {
      return date.split(",")[0];
    },
    month(date) {
      return date.split(",")[1];
    },
    day(date) {
      return date.split(",")[2];
    },
    format(number) {
      return format(number);
    },
    async buyTicket(method) {
      try {
        await this.$store.dispatch("workshops/buyTicket", {
          ticket: this.ticket.id,
          method: method,
        });
        this.closeModal();
        await this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.requestSuccess,
        });
        this.$router.replace("/");
      } catch (error) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.quoteFail,
        });
        // console.log(error);
      }
    },
  },
};
</script>
